const colors = {
    accent0: 'orange',
    accent1: '#00baff',
    accent2: 'antiquewhite',
    accent3: 'mediumspringgreen',
    accent4: 'ivory',
    accent5: 'gold',
    backgroundColor0: '#000',
    textColor0: '#fff',
    backgroundColor1: '#fff',
    textColor1: '#000',
    backgroundColor2: '#5B84B1',
    textColor2: '#FC766A',
    backgroundColor3: '#5F4B8B',
    textColor3: '#E69A8DFF',
    backgroundColor4: '#F2AA4C',
    textColor4: '#101820',
    backgroundColor5: '#00203F',
    textColor5: '#ADEFD1'
}

export default colors;